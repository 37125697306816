<template>
    <v-dialog
        v-model="dialog" 
        max-width="900px" 
        class="dropzone-depth-fix"
    >
        <template v-slot:activator="{ on, attrs }">
                <v-card 
                    class="d-flex transparent py-1"
                    flat 
                    tile
                >
                    <v-tooltip 
                        v-model="tooltip" 
                        top
                        :disabled="!mediaFilename"
                    >
                        <template v-slot:activator="{}">
                            <v-hover v-model="hover">
                                <v-card 
                                    class="flex-shrink-1" 
                                    flat 
                                    tile
                                    v-bind="attrs" 
                                    v-on="on"
                                >
                                    <slot name="thumbnail">
                                        <v-card 
                                            class="grey lighten-3 d-flex justify-center align-center"
                                            width="80"
                                            height="40"
                                            flat
                                        >
                                            <v-icon size="20" color="primary">mdi-panorama</v-icon>
                                        </v-card>
                                    </slot>
                                    <v-overlay
                                        v-if="hover"
                                        color="primary"
                                        absolute
                                    >
                                        <v-icon class="icon-flipped">mdi-open-in-new</v-icon>
                                    </v-overlay>
                                </v-card>
                            </v-hover>
                        </template>
                        <span class="caption">{{ mediaFilename }}</span>
                    </v-tooltip>
                </v-card>
        </template>
        <v-card v-if="dialog"> <!-- destroys component instead of display:none otherwise persists in DOM and video player remains active -->
            <v-card-text class="pt-4 pb-0">
                <div>WIDGET: <span class="subtitle-1 font-weight-medium">{{ itemFilename }}</span></div>
                <div class="pb-1">MEDIA: <span class="subtitle-1 font-weight-medium">{{ mediaFilename }}</span></div>
                <video-player 
                    v-if="isVideo(mediaUrl)"
                    :src="mediaUrl"
                />
                <v-img 
                    v-if="isImage(mediaUrl)"
                    :src="mediaUrl" 
                    :aspect-ratio="16/9" 
                    contain 
                >
                    <template v-slot:placeholder>
                        <v-row class="fill-height ma-0 grey lighten-4" align="center" justify="center">
                            <v-progress-circular color="primary" size="30" width="4" indeterminate />
                        </v-row>
                    </template>
                </v-img>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    depressed
                    @click="dialog = false"
                >
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import VideoPlayer from "@/components/VideoPlayer.vue"
import { getFileExtension } from '@/services/util'

export default {
    name: "MediaPreview",
    components: {
        VideoPlayer
    },
    props: {
        itemFilename: {
            type: String,
            required: true
        },
        mediaFilename: {
            type: String,
            required: true
        },
        mediaUrl: {
            type: String,
            required: true
        }
    },
    data: () => ({
        dialog: false,
        hover: false,
        tooltip: false
    }),
    methods: {
        isVideo(url) {
            return ['mp4', 'mov'].includes(getFileExtension(url).toLowerCase())
        },
        isImage(url) {
            return ['jpg', 'jpeg', 'png'].includes(getFileExtension(url).toLowerCase())
        }
    },
    watch: {
        hover (to) {
            this.tooltip = to
        }
    }
}
</script>

<style scoped>
.icon-flipped {
    -o-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
}
</style>
