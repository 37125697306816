<template>
    <v-card flat>
        <video ref="videoPlayer" class="video-js vjs-16-9" max-height="50%"></video>
        <v-overlay
          :value="overlay"
          color="grey lighten-2"
          absolute
        >
            <v-progress-circular color="primary" size="30" width="4" indeterminate />
        </v-overlay>
    </v-card>
</template>

<script>
import videojs from 'video.js';

export default {
    name: "VideoPlayer",
    props: ['src'],
    data () {
        return {
            options: {
                autoplay: true,
                loop: true,
                sources: [
                    {
                        src: this.src,
                        type: "video/mp4"
                    }
                ]
            },
            player: null,
            overlay: true
        }
    },
    mounted () {
        this.player = videojs(this.$refs.videoPlayer, this.options, function onPlayerReady() {
            // console.log('onPlayerReady', this);
        })
        this.player.on('play', () => this.overlay = false)
    },
    beforeDestroy () {
        if (this.player) {
            this.player.dispose()
        }
    }
}
</script>

<style>
@import '~video.js/dist/video-js.css';

video {
    background-color: #ffffff
}
</style>